import { useEffect } from 'react';
import { Base64 } from 'js-base64';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { SpidAPI } from '../Apis/SpidAPI'
import { FastIdAPI } from '../Apis/FastIdAPI'
import { getConfiguration, getServicePath } from '../util';

export default function Loading() {
  const navigate = useNavigate();

  const [resHtml, setResHtml] = useState('')
  let params = useParams()

  const redirectToService = async (token) => {
    await getConfiguration(token, window.location.origin);
    const redirect = getServicePath();
    // console.log("redirect", redirect);
    // window.location.href = redirect;
    navigate(redirect);
  }

  useEffect(() => {
    let abortController

    (async () => {
      abortController = new AbortController()
      let signal = abortController.signal
      try {
        if (params.idp) {
          abortController = new AbortController()
          let signal = abortController.signal

          if (params.idp) {
            const res = await SpidAPI.SPIDlogin(params.idp, signal)

            setResHtml(Base64.decode(res.data));

            setTimeout(() => {
              document.forms['my-form'].submit()
            }, 1000)
          }
        }
        if (params.token) {
          let redirect = "/";
          if (localStorage.getItem('destination')) {
            redirect = localStorage.getItem('destination')
          }
          const res = await SpidAPI.SPIDAuthentication(params.token, signal)

          const data = res.data
          const token = data.access_token

          if (!token) {
            alert('Unable to login. Please try after some time.')
            return
          }
          // console.log('if token', token)
          localStorage.removeItem('access_token')
          localStorage.setItem('access_token', token)
          localStorage.setItem('user', JSON.stringify(data.user))
          redirectToService(token);
          // navigate(redirect)
        } else {
          const queryString = window.location.search;
          const urlParam = new URLSearchParams(queryString);

          const urlToken = urlParam.get("SpidToken");

          let redirect = localStorage.getItem('destination') ?? "/";

          const res = await FastIdAPI.getProfile(urlToken)

          const data = res.data;
          const token = data?.access_token || "invalid_token"

          // console.log("access_token", token);
          window.localStorage.setItem('access_token', token)
          window.localStorage.setItem('user', JSON.stringify(data.user))
          // sessionStorage.setItem("isLoggedIn", true);


          redirectToService(token);
          // window.location.href = redirect;
          // window.location.assign(redirect);
          // navigate(redirect)

        }
      } catch (err) {
        localStorage.setItem('login_error', "invalid_token")
      }
    })()
    return () => abortController.abort()
  }, [])


  return (
    <>
      <React.Fragment>
        <div dangerouslySetInnerHTML={{ __html: resHtml }} id='redirect'></div>
        <>
          <section className='main-wrapper u-layout u-layout-wide u-layoutCenter'>
            <header className='main-header u-padding-all-xxl'>
              <div className='main-header__inner Grid Grid--fill Grid--withGutter Grid--equalHeight Grid--alignMiddle'>
                <div className='u-size1of2'>
                </div>
                <div className='u-size1of2'>
                  <div className='u-textRight'>
                    <span className='u-text-r-xs u-color-grey-40 u-padding-all-xs u-border-all-xxs'></span>
                  </div>
                </div>
              </div>
            </header>
            <main className='main u-layout u-layout-extrasmall u-layoutCenter '>
              <article className='main-bodytext u-padding-all-xl'>
                <div className='u-textCenter'>
                  <img
                    src='../assets/images/loading.svg'
                    className='icon-error'
                    onerror="this.src='../assets/images/loading.png'; this.onerror=null;"
                    alt='SPID Logo'
                    style={{
                      margin: '0px auto',
                      justifyContent: 'center',
                      textAlign: 'center',
                      transform: 'translateX(-50%)',
                      position: 'absolute',
                      top: '30%',
                      left: '50%'
                    }}
                  />
                </div>
                <p className='u-text-r-xs u-color-grey-40 u-textWeight-400 u-margin-top-l u-lineHeight-l u-textCenter'
                  style={{ margin: '0px auto', justifyContent: 'center', textAlign: 'center', transform: 'translateX(-50%)', position: 'absolute', top: '65%', left: '50%' }}>
                  Ti stiamo trasferendo al servizio da te richiesto
                </p>
              </article>
            </main>
          </section>
        </>
      </React.Fragment>
    </>
  )
}